export const NEWS_SITE_DOMAINS = [
	'https://news.jtbc.co.kr',
	'https://news-app.jtbc.co.kr/',
	'https://mnews.jtbc.co.kr/',
	'https://dev-news.jtbc.co.kr',
	'https://dev-news-app.jtbc.co.kr/',
	'https://dev-mnews.jtbc.co.kr/',
	'https://stg-news.jtbc.co.kr',
	'https://stg-news-app.jtbc.co.kr/',
	'https://stg-mnews.jtbc.co.kr/',
]
