'use clinet'

import { Grid } from '@mui/joy'

import { useArticleRankViewQuery } from '@services/api/Article/RankView/query'

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { getRelativeTime } from '@utils/date'

import { getArticleLink } from '@utils/getLink'
import { useCloseHeaderState } from '../../store/store'
import SearchRankArticleItem from './SearchRankArticleItem'
import UIDivider from '@components/ui/UIDivider'

const SearchRankArticleList = ({ darkMode }: { darkMode?: boolean }) => {
	const { data } = useArticleRankViewQuery({ pageSize: 3 })

	const handleSearchClose = useCloseHeaderState('searchOpen')

	return (
		<Grid
			container
			columnSpacing={{ md: pxToRem(24), lg: pxToRem(32) }}
			minHeight={{ md: pxToRem(223) }}
			sx={{
				'&>*:not(:first-child)': {
					marginTop: {
						xs: pxToRem(12),
						md: 0,
					},
				},
			}}
		>
			{data?.rankArticleList?.map((article, index) => (
				<>
					{index !== 0 && (
						<UIDivider
							orientation={{ xs: 'horizontal', md: 'vertical' }}
							sx={{
								background: darkMode ? '#303030' : color.colGray3,
							}}
						/>
					)}

					<Grid key={`${article.articleIdx}`} xs={12} md={4} sx={{}}>
						<SearchRankArticleItem
							content={{
								title: article.articleTitle,
								href: getArticleLink(article),
								src: article.articleThumbnailImgUrl ?? '',
								additionalText: getRelativeTime(article.publicationDate),
								duration: article.vodInfo ? article.vodInfo.playTime : '',
								showVideoViewIcon: article.isVideoView,
								articleThumbnailImageViewType:
									article.articleThumbnailImageViewType,
								onClick: handleSearchClose,
							}}
						/>
					</Grid>
				</>
			))}
		</Grid>
	)
}

export default SearchRankArticleList
