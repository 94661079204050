'use client'

import UIFlex from '@components/ui/UIFlex'
import { pxToRem } from '@ui/style/muiTheme'
import UIText from '@components/ui/UIText'
import { Icon32Close } from 'src/assets/icons/Icon32Close'
import { JTBCLogo, JTBCLogoArea, NEWSLogo } from '../NavBox/styled'
import { useCloseHeaderState } from '../store/store'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import color from '@ui/style/color.theme'
import { useIsAppControl } from '@store/isApp'

const SearchMobileHeader = () => {
	const { isApp } = useIsAppControl()
	const { darkMode } = useDarkModeControl()
	const handleSearchClose = useCloseHeaderState('searchOpen')

	return (
		<UIFlex
			display={{ xs: 'flex', md: 'none' }}
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			pl={pxToRem(20)}
			pr={pxToRem(12)}
			flexShrink={0}
			height={pxToRem(50)}
		>
			<JTBCLogoArea>
				<JTBCLogo href={isApp ? '/' : process.env.NEXT_PUBLIC_JTBC_URL} darkMode={darkMode}>
					<UIText readonly>JTBC</UIText>
				</JTBCLogo>
				<NEWSLogo href="/" darkMode={darkMode}>
					<UIText readonly>NEWS</UIText>
				</NEWSLogo>
			</JTBCLogoArea>
			<button type="button" onClick={handleSearchClose}>
				<Icon32Close color={darkMode ? color.colGray1 : color.colBlack} />
				<UIText readonly>닫기</UIText>
			</button>
		</UIFlex>
	)
}

export default SearchMobileHeader
